.footer {
    /* background-color: #db6930; */
    color: white;
    padding-top: 1rem;
    text-align: center;
    width: 100%;
    position: relative;
    bottom: 0;   
    /* background-image: linear-gradient(55deg, #b32877, #c45959, #a78ac0); */
    background-image: linear-gradient(55deg, #5c5559, #857474, #5b5461);
    /* background-image: linear-gradient(180deg, black, grey); */
    /* background-image: linear-gradient(1deg, #1b1a1a, grey, black); */



    .container {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    .btn-floating {
        color: white;
        font-size: 20px;
        border: none;

        &:hover {
            opacity: 0.8;
        }
    }

    .text-center {
        background-color: rgba(0, 0, 0, 0.2);
    }
}
a{
    color:white;
}
@media (max-width: 576px) {
    .container {
        flex-direction: column;

        .btn-floating {
            margin-bottom: 10px;
        }
    }
}
