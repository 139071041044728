.error-page-container {
    height: 100vh; /* Full height of the viewport */
    background-size: cover; /* Cover the entire background */
    /* background: url('/public/images/ErrorImage.jpeg') no-repeat center center; */
}

.error-info {
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    border-radius: 10px; /* Rounded corners */
    max-width: 600px; /* Responsive width */
    width: 100%;
}

h1, h5, h3 {
    color: #333; /* Dark text for better readability */
}

@media (max-width: 768px) {
    .error-info {
        padding: 2rem;
    }
    
    h1 {
        font-size: 2rem; /* Smaller font size for mobile */
    }

    h5, h3 {
        font-size: 1.25rem;
    }
}
