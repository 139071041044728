.card-container {
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px; 
}

.card {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.card:hover .card-inner {
    transition: transform 0.6 ;
    transform: rotateY(180deg);
}

.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hide the back face when turned */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent text overflow */
    padding: 10px; /* Responsive padding */
    box-sizing: border-box; /* Include padding and border in width/height */
}

.card-front {
    background-image: linear-gradient(55deg, #b32877, #c45959, #a78ac0);
    color: white;
    text-align: center; /* Center the text */
}

.card-back {
    font-weight: bold;
    font-family: monospace;
    color: #000000;
    transform: rotateY(180deg); 
    overflow-y: auto; 
    text-align: justify;
}

/* Media Query for smaller screens */
@media (max-width: 100px) {
    .card {
        height: auto; /* Allow height to auto-adjust for text */
        min-height: 200px; /* Set a minimum height */
    }

    .card-front{
        background-image: linear-gradient(55deg, #b32877, #c45959, #a78ac0);
        font-size: 12px; /* Reduce font size for smaller screens */
        padding: 8px; /* Reduce padding for smaller screens */
    }

    .card-back {
        padding-top: 70px !important;
        overflow-y: auto; /* Allow scrolling for longer text */
        font-size: 12px; /* Reduce font size for smaller screens */
        padding: 8px; /* Reduce padding for smaller screens */
    }
    .flex-row{
        flex-direction: column !important;
    }
}

.container {
    margin-top: 6rem !important;
}

.company-details {
    background-image: linear-gradient(55deg, #b32877, #c45959, #a78ac0);
}

.company-details p {
    color: white;
}
